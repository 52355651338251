var stripeScript
export default {
  methods: {
    loadStripeScript(publicKey, success = () => { }) {
      console.log(publicKey)
      if (!stripeScript) {
        stripeScript = window.document.createElement('script')
        stripeScript.src = '//js.stripe.com/v3/'
        stripeScript.onload = () => {
          console.log('loadStripeScript成功===========')
          window.stripe = Stripe(publicKey)
          window.stripeElements = window.stripe.elements()
          success && success()
        }
        var head = window.document.head || window.document.getElementsByTagName('head')[0] || window.document.documentElement
        head.insertBefore(stripeScript, head.firstChild)
      } else {
        console.log('Stripe已加载')
        window.stripe = Stripe(publicKey)
        window.stripeElements = window.stripe.elements()
        success && success()
      }
    }
  }
}
